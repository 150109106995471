.BookLink {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 0.5rem;
  height: 5.5rem;
  width:  5.5rem;
  z-index: 6;
  svg {
    height: 100%;
    width:  100%;
    stroke-width: 0.06;
    stroke: black;
    fill: white;
  }
}