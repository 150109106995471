.Collapse {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &__content {
    width: 100%;
    overflow-y: hidden;
    transition: max-height 300ms ease-in-out;
  }
  &__buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 60rem;
    &--lined {
      background: linear-gradient(transparent 48%, silver 52%, transparent 52%);
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 12rem;
    padding: 0.9rem 2rem;
    border: 1px solid silver;
    border-radius: 10px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: rgb(70,70,70);
    background-color: whitesmoke;
  }
}