.YiChingIntro {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  background-color: white;
  figure {
    width: 15rem;
    max-width: 100%;
    img {
      max-width: 100%;
      margin-bottom: 0.3rem;
    }
    figcaption {
      font-size: 1rem;
      text-align: center;
    }
  }
  &__part {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 60rem;
    margin-bottom: 2rem;
    &--left {
      justify-content: space-between;
      @include landscape {
        flex-direction: row;
        align-items: flex-start;
      }
    }
    &--right {
      align-items: stretch;
      p {
        width: 100%;
        margin-bottom: 2rem;
        text-align: justify;
      }
    }
  }
  &__technical {
    margin-bottom: 2rem;
    tr {
      margin-bottom: 0.6rem;
      td {
        padding: 0.3rem 0.5rem;
        font-size: 1.3rem;
      }
      td:first-child {
        font-size: 1.1rem;
        color: $gray4;
      }
    }
  }
}
