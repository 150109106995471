.OfferCard {
  $w: 27rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $w;
  height: 1.618 * $w;
  margin: 1rem;
  padding: 3rem;
  border: 2px solid black;
  border-radius: 12px 24px;
  box-shadow: 6px 12px 18px 0 rgba(0,0,0,0.5);
  // background: linear-gradient(115deg, rgb(255,255,240), rgb(255, 240, 180) 40%, rgb(255, 190, 150));
  background: linear-gradient(115deg, rgb(30,30,50), rgb(40, 30, 40), black);
  @include tablet {
    margin: 3rem;
  }
  &__heading {
    width: 100%;
    margin-bottom: 2.5rem;
    padding: 0.5rem;
    border-bottom: 1px solid ivory;
    font-size: 1.8rem;
    text-align: center;
    text-transform: uppercase;
    color: ivory;
  }
  &__content {
    flex-grow: 1;
    list-style-type: none;
    color: white;
    svg {
      width:  1.1rem;
      height: 1.1rem;
      margin-right: 0.8rem;
      stroke-width: 0.1;
      stroke: gold;
      fill: gold;
    }
    li {
      margin-bottom: 1.5rem;
    }
  }
  &__price {
    text-align: center;
    strong {
      font-size: 5rem;
      color: rgb(255,255,100);
    }
    span {
      margin-left: 0.7rem;
      font-size: 1.4rem;
      color: $gray4;
    }
    em {
      font-size: 0.9rem;
      color: $gray3;
    }
  }
}