.Pricing {
  display: flex;
  justify-content: center;
  padding: 12rem $padH;
  background: rgba(230,230,230,0.5);
  z-index: 3;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: $maxW;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 5rem;
    padding: 1.5rem 6rem;
    border: 1px solid indigo;
    border-radius: 500px;
    background: linear-gradient(to bottom right, gainsboro, silver 30%, white, silver, gainsboro);
    color: black;
    cursor: default;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
    h2 {
      margin-bottom: 0.8rem;
      font-size: 2.1rem;
      font-variant: small-caps;
    }
  }
  &__offers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include landscape {
      flex-direction: row;
    }
  }
}