.LangNav {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 6;
  &__inner {
    display: flex;
    flex-direction: column;
    padding: 3rem 0.5rem;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.6rem;
    height: 3.6rem;
    margin: 0.6rem 0;
    border: none;
    border-radius: 100%;
    overflow: hidden;
    background-color: black;
    box-shadow: 0 0 0 1px black;
  }
  svg {
    height: 100%;
    width: 100%;
  }
}