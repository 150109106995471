.Contact {
  display: flex;
  justify-content: center;
  padding: 12rem $padH;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 60rem;
    padding: 3rem 6rem;
    border: 2px solid silver;
    box-shadow: 0 5px 12px 0 rgb(0,0,0,0.5);
    background-color: white;
    z-index: 3;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    h1 {
      margin-bottom: 1rem;
      font-size: 3rem;
      font-variant: small-caps;
      color: teal;
    }
    .Separator {
      background-color: teal;
    }
  }
  &__mail {
    padding: 3rem 0;
  }
}