.YiChing {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 $padH;
  z-index: 3;
  background: rgba(white, 0.8);
  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 66rem;
    padding: 6rem 0;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
    h1 {
      font-variant: small-caps;
      font-size: 3rem;
    }
    h3 {
      margin: 1rem 0;
      text-transform: uppercase;
      font-size: 1.5rem;
    }
  }
}