// Fonts

@font-face {
  font-family: VarelaRound;
  src: url(../../assets/fonts/VarelaRound-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: ChineseSerif;
  src: url(../../assets/fonts/NotoSerifTC-Medium.otf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Shojumaru;
  src: url(../../assets/fonts/Shojumaru-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Federica;
  src: url(../../assets/fonts/FrederickatheGreat-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Cinzel;
  src: url(../../assets/fonts/CinzelDecorative-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}