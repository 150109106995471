// Variables

$headerH: 6rem;
$maxW: 100rem;
$padH: 1rem;

$gray0: rgb(40, 40, 40);
$gray1: rgb(70, 70, 70);
$gray2: rgb(110, 110, 110);
$gray3: rgb(150, 150, 150);
$gray4: rgb(180, 180, 180);
$gray5: rgb(210,210,210);
$gray6: rgb(240, 240, 240);