.Shooter {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  &__bullet {
    width: var(--w);
    z-index: 2;
    position: absolute;
    left: calc(var(--x) - 0.5*var(--w));
    top: calc(var(--y) - 0.5*var(--w));
  }
  &__bulletContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    user-select: none;
    color: gray;
    p {
      margin-top: calc(0.2*var(--w));
      font-size: calc(0.15*var(--w));
      text-align: center;
    }
  }
}