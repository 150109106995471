.YiChingHead {
  width: 100%;
  text-align: center;
  margin-top: 12rem;
  margin-bottom: 15rem;
  &__title {
    margin-bottom: 3rem;
    font-size: 5rem;
    font-family: "Federica", serif;
    font-weight: 400;
    text-transform: uppercase;
    text-shadow: 3px 3px rgba(0,0,0,0.2);
    color: black;
  }
  &__subtitle {
    margin-bottom: 1rem 0;
    text-transform: uppercase;
    font-family: "Cinzel", serif;
    word-spacing: 0.7rem;
  }
  &__cta {
    margin: 5rem 0;
    padding: 1rem;
    text-align: center;
    color: orangered;
    background-color: white;
  }
}