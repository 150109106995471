.YiChingOracle {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__textWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 66rem;
    padding: 1rem 0;
    background-color: white;
  }
  &__text {
    max-width: 60rem;
  }
  p {
    margin-bottom: 2rem;
    text-align: justify;
  }
  &__trigrams {
    width: 100%;
    max-width: 48rem;
    margin-top: 6rem;
    fill: transparent;
    stroke: silver;
  }
}