.YiChingOccident {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  figure {
    flex-shrink: 0;
    width: 18rem;
    max-width: 100%;
    margin-bottom: 2rem;
    img {
      max-width: 100%;
      margin-bottom: 0.3rem;
      border-radius: 50%;
    }
    figcaption {
      font-size: 1rem;
      text-align: center;
    }
    @include tablet {
      margin-right: 3rem;
    }
  }
  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 60rem;
    margin-bottom: 4rem;
    @include tablet {
      flex-direction: row;
      align-items: flex-start;
      &:nth-child(2n) {
        flex-direction: row-reverse;
        figure {
          margin-right: 0;
          margin-left: 3rem;
        }
      }
    }
    &Info {
      width: 100%;
      background-color: white;
    }
  }
  p {
    margin-bottom: 1.5rem;
    text-align: justify;
  }
}